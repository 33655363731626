import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { fireStoreActivityWatcher } from "../../Auth/firebase/firebaseQueries";
import {
  getAllChats,
  getAllGroups,
  getAllUserMapping,
  getAllUsers,
  updateGroup,
  updateUser,
} from "../../IndexedDB/queries";

import { getAdCampainDetails } from "../../Auth/api";
import { sortChatList } from "../CommonThings/CommonThings";
import ChatList from "./ChatList/ChatList";
import AdComp from "./AdComp/AdComp";
import OTPComponent from "../chat/otpComponent/OTPComponent";
import {
  ListContainer,
  ListMapping,
  MenuItems,
  MenuToggle,
  SearchIcon,
  SelectIcon,
  SidebarHeader,
  SidebarMain,
  SidebarMenu,
} from "./sidebar.styled";
import { XYCenteredContent } from "../CommonThings/common.styled";

const Sidebar = ({
  sidebarRef,
  sidebar,
  setSidebar,
  getChatDetail,
  contactID,
  chatDetail,
  getAdcampaign,
  clearConversation,
  setChatDetail,
  callLogoutAPI,
  setContactID,
  callPendingInviteAPI,
  setGroupChatMessages,
  clearMessageInput,
}) => {
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [userCopyData, setUserCopyData] = useState([]);

  const [searchResult, setSearchResult] = useState({ chats: [], messages: [] });

  // enter new user otp
  const [showOTPModel, setShowOTPModel] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [updateSenderData, setUpdateSenderData] = useState(null);

  // for ad campaign
  const [showAds, setShowAds] = useState(false);
  const [adData, setAdData] = useState([]);

  //select users/chat
  const [select, setSelect] = useState(false);

  const keyDownHandler = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setSelect(false);
      setShowAds(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    fetchAllChats();
    sidebarRef.current = {
      fetchAllChats,
      addLastMessageInUserList,
      showModelAndSetUpdateData,
      onUserListClick,
      setUserData,
      setUserCopyData,
      callPendingInviteAPI,
    };

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const get_campaign_data = async () => {
    const campaign_data = await getAdCampainDetails({
      sessionId: contactID,
      type: "nonappuser",
    });
    let temp_arr = [];
    campaign_data?.data?.forEach((ad) => {
      const temp_data = {
        is_ad: true,
        contactID: contactID,
        userData: {
          SenderName: ad.campaignName,
          Profilepic: ad.mainImage,
          is_verified: true,
        },
        lastMessage: {
          api_response: {
            body: ad.Description,
            sentTime: `${ad.startDate} to ${ad.endDate}`,
          },
        },
        is_verified: true,
        banner: ad.Banner,
      };
      temp_arr.push(temp_data);
    });
    setAdData(temp_arr);
  };

  useEffect(() => {
    if (contactID?.trim()) {
      get_campaign_data();
    }
  }, [contactID]);

  useEffect(() => {
    if (userData.length) {
      setContactID(
        userData[0]?.userData?.contactID?.toString() ??
          userData[0]?.groupData?.contactID?.toString()
      );
    }
  }, [userData]);

  useEffect(() => {
    if (updateSenderData) {
      updateUserListFunction();
    }
  }, [updateSenderData]);

  const updateUserListFunction = async () => {
    const newUpdateData = userCopyData.map(async (user) => {
      if (
        user?.userData &&
        Number(user.userData.SenderID) === Number(updateSenderData.uid)
      ) {
        let updatedUser = { ...user, firebaseData: updateSenderData };
        await updateUser(updatedUser);
        return updatedUser;
      } else {
        return user;
      }
    });
    setUpdateSenderData(null);

    setUserData(newUpdateData);
    setUserCopyData(newUpdateData);

    const newUpdateInIndexedDBData = userCopyData.map(async (user) => {
      if (
        user?.userData &&
        Number(user.userData.SenderID) === Number(updateSenderData.uid)
      ) {
        await updateUser({ ...user, firebaseData: updateSenderData });
        return { ...user, firebaseData: updateSenderData };
      } else {
        return user;
      }
    });

    //When web user is logged-in and someone sends invitation
    contactID && (await callPendingInviteAPI(contactID));
  };

  const fetchAllChats = async (webID = null, groupID = null) => {
    const sorted_chats = await sortChatList();
    if (sorted_chats.length > 0) {
      var displayedChat;
      if (webID) {
        //userList
        displayedChat = await sorted_chats.find(
          (user) => user?.userData?.webID === webID
        );
        if (displayedChat) {
          displayedChat.unReadCount = 0;
          await updateUser(displayedChat);
        }
      } else if (groupID) {
        //groupList
        displayedChat = sorted_chats.find(
          (group) => group?.groupData?.groupID === groupID
        );
        if (displayedChat) {
          displayedChat.unReadCount = 0;
          await updateGroup(displayedChat);
        }
      } else {
        if (sorted_chats[0]?.unReadCount > 0) {
          sorted_chats[0].unReadCount = 0;
        }
      }

      setUserData(sorted_chats);
      setUserCopyData(sorted_chats);

      if (displayedChat) {
        await getChatDetail(displayedChat);
      } else {
        const first_chat = sorted_chats[0];
        sorted_chats.length &&
          first_chat.is_verified &&
          (await getChatDetail(first_chat));
      }

      fireStoreActivityWatcher(contactID, setUpdateSenderData);

      return sorted_chats;
    } else {
      setUserData([]);
      setUserCopyData([]);
    }
  };

  const addLastMessageInUserList = async (lastMessage, senderID) => {
    const all_users = await getAllUserMapping();
    const logged_in_user = all_users.data.find((user) => user.is_login);

    const all_userList = await getAllUsers();

    if (all_userList.status === 1) {
      let userList = await all_userList.data.filter(
        (user) => user.userData.contactID == logged_in_user.contact_id
      );
      if (userList.length > 0) {
        const newUpdateInIndexedDBData = await userList.map(async (user) => {
          if (
            Number(user.userData.SenderID) ===
            Number(lastMessage.api_response.recipientUserID)
          ) {
            const newUser = { ...user, lastMessage: lastMessage };
            if (
              lastMessage.role === "sender" &&
              Number(senderID) !==
                Number(lastMessage.api_response.recipientUserID)
            ) {
              const unReadCount = user?.unReadCount ? user.unReadCount + 1 : 1;
              newUser.unReadCount = unReadCount;
            } else {
              newUser.unReadCount = 0;
            }
            const updated_user = await updateUser(newUser);
            return newUser;
          } else {
            return user;
          }
        });
      }

      const all_groupList = await getAllGroups();
      if (all_groupList.status === 1) {
        let groupList = await all_groupList.data.filter(
          (group) => group?.groupData?.contactID == logged_in_user.contact_id
        );
        if (groupList.length > 0) {
          const updateIndexedDBGroups = groupList.map(async (group) => {
            if (
              Number(group?.groupData?.groupID) ===
              Number(lastMessage?.groupID || lastMessage?.api_response?.groupID)
            ) {
              const newGroup = { ...group, lastMessage: lastMessage };
              if (
                lastMessage.role === "sender" &&
                Number(senderID) ===
                  Number(
                    lastMessage?.groupID || lastMessage?.api_response?.groupID
                  )
              ) {
                const unReadCount = group?.unReadCount
                  ? group.unReadCount + 1
                  : 1;
                newGroup.unReadCount = unReadCount;
              } else {
                newGroup.unReadCount = 0;
              }
              const updated = await updateGroup(newGroup);
              return newGroup;
            } else {
              return group;
            }
          });
        }
      }

      // if (lastMessage.role !== "sender") {
      const sortedChats = await sortChatList();
      setUserData(sortedChats);
      setUserCopyData(sortedChats);
      // }
    }
  };

  useEffect(() => {
    localStorage.setItem("search", search.trim() ? true : false);
  }, [search]);

  const searchFunction = async (searchValue) => {
    try {
      let allChats = await sortChatList();
      allChats = allChats.filter((chat) => !chat.is_ad);
      setSearch(searchValue);
      if (searchValue.trim()) {
        let searchString = searchValue.toString().trim().toLowerCase();
        if (searchString.length > 0) {
          //by chat name

          let chatsFilteredByName = allChats.filter((user) =>
            user?.userData?.SenderName
              ? user?.userData?.SenderName?.toString()
                  .toLowerCase()
                  .includes(searchString)
              : user?.groupData &&
                user?.groupData?.groupName
                  ?.toString()
                  .toLowerCase()
                  .includes(searchString)
          );

          //by message
          const getAllMessages = await getAllChats();
          if (getAllMessages.status === 1) {
            const allMessages = getAllMessages.data;
            const filteredMessages = allMessages.filter((message) =>
              message?.api_response?.typeID == 5
                ? message?.api_response?.filePath
                    ?.split("/")
                    ?.slice(-1)[0]
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(searchString)
                : message?.api_response?.body
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(searchString)
            );

            setSearchResult({
              chats: chatsFilteredByName,
              messages: filteredMessages,
            });
          }
        }
      } else {
        setSearchResult({
          chats: [],
          messages: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showModelAndSetUpdateData = (user) => {
    setShowOTPModel(true);
    setUpdateData(user);
  };

  const clearUnReadCountFromUser = async (chat) => {
    const newData = userData.map((data) => {
      if (data.id === chat.id) {
        const update_chat = { ...data, unReadCount: 0 };
        if (chat?.userData) {
          updateUser(update_chat);
        } else if (chat?.groupData) {
          updateGroup(update_chat);
        }
        return update_chat;
      } else {
        return data;
      }
    });

    setUserData(newData);
    setUserCopyData(newData);
  };

  const onUserListClick = async (chat) => {
    if (chat.is_verified) {
      if (JSON.stringify(chatDetail) != JSON.stringify(chat)) {
        clearUnReadCountFromUser(chat);
        getChatDetail(chat);
        if (chat.userData) {
        } else if (chat.groupData) {
          // setGroupChatMessages([]);
          await fetchAllChats(null, chat.groupData.groupID);
        }
      }
    } else {
      !select && showModelAndSetUpdateData(chat);
    }
    clearMessageInput();
    setSidebar(false);
  };

  return (
    <>
      <SidebarMain className={`${sidebar ? "m-0" : ""}`}>
        <SidebarHeader justify="space-between">
          <Button onClick={() => setSidebar(false)}>
            <img src="./images/ic_menu.ico" alt="menu" width="25" height="25" />
          </Button>
          <SearchIcon />
          <input
            type="text"
            placeholder={"Search"}
            onChange={(e) => searchFunction(e.target.value)}
            value={search}
          />
          <XYCenteredContent>
            <SidebarMenu>
              <Dropdown>
                <MenuToggle id="dropdown-basic">
                  <img src="./images/show.ico" width="23" alt="" />
                </MenuToggle>

                <MenuItems>
                  <Dropdown.Item className="p-0">
                    <Button
                      className="my-0 w-100 px-3 py-3"
                      onClick={() => setSelect(true)}
                    >
                      <SelectIcon />
                      <span>Select Chat</span>
                    </Button>
                  </Dropdown.Item>
                </MenuItems>
              </Dropdown>
            </SidebarMenu>
          </XYCenteredContent>
        </SidebarHeader>
        <ListContainer>
          <ListMapping showAds={showAds}>
            <ChatList
              userData={userData}
              onUserListClick={onUserListClick}
              fetchAllChats={fetchAllChats}
              clearConversation={clearConversation}
              setChatDetail={setChatDetail}
              callLogoutAPI={callLogoutAPI}
              select={select}
              setSelect={setSelect}
              setShowOTPModel={setShowOTPModel}
              chatDetail={chatDetail}
              search={search}
              searchResult={searchResult}
            />
            <AdComp
              showAds={showAds}
              setShowAds={setShowAds}
              adData={adData}
              getAdcampaign={getAdcampaign}
            />
          </ListMapping>
        </ListContainer>
      </SidebarMain>
      <OTPComponent
        show={showOTPModel}
        setShow={setShowOTPModel}
        getUserData={fetchAllChats}
        updateData={updateData}
        setSidebar={setSidebar}
        sidebarRef={sidebarRef}
      />
    </>
  );
};

export default Sidebar;
