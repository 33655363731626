import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  MembersList,
  SelectMembers,
  SelectMembersContainer,
  SelectMembersInput,
  SelectedMember,
  SendToTxt,
} from "./selectSpecificMembers.styled";

function SelectSpecificMembers({ chatDetail, setSpecificMembers }) {
  const [showMembers, setShowMembers] = useState(false);
  const [sendMessageTo, setSendMessageTo] = useState([]);
  const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [parentHeight, setParentHeight] = useState(0);

  const inputRef = useRef(null);
  const memberRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", clickEventDOM);
    window.addEventListener("resize", updateParentSize);

    return () => {
      document.removeEventListener("click", clickEventDOM);
      window.removeEventListener("resize", updateParentSize);
      setSpecificMembers([]);
    };
  }, []);

  const updateParentSize = () => {
    const parentDiv = document.getElementById("memberListParent");
    setParentHeight(parentDiv?.offsetHeight);
  };

  const clickEventDOM = (e) => {
    updateParentSize();
    const memberInput = document.getElementById("member-input");
    if (e.target != memberInput) {
      setShowMembers(false);
    }
  };

  const setGroupMembers = () => {
    const members = chatDetail?.groupData?.members.filter(
      (m) => m.ID != chatDetail.groupData.contactID
    );
    setCurrentGroupMembers(members);
  };

  useEffect(() => {
    if (chatDetail?.groupData) {
      setGroupMembers();
    }
    setSpecificMembers(sendMessageTo);
    updateParentSize();
  }, [chatDetail, sendMessageTo]);

  useEffect(() => {
    setSendMessageTo([]);
  }, [chatDetail]);

  const selectMemberOnClick = (e, member) => {
    const checkExistance = sendMessageTo.find((m) => m.ID == member.ID);
    if (currentGroupMembers.length === 1) {
      setCurrentGroupMembers([]);
    }
    if (!checkExistance) {
      setSendMessageTo([...sendMessageTo, member]);
    }
    if (!inputValue) {
      setGroupMembers();
    }
    setInputValue("");
  };

  const removeSelectedMember = (e, member) => {
    const removeMember = sendMessageTo.filter((m) => m.ID !== member?.ID);
    setSendMessageTo(removeMember);
  };

  const filterMembers = (e) => {
    setInputValue(e?.target?.value);
    const inputStr = e?.target?.value?.toLowerCase()?.trim();
    const foundMembers = chatDetail?.groupData?.members?.filter((m) => {
      if (m.ID != chatDetail.groupData.contactID) {
        const findMembers =
          !sendMessageTo.includes(m) &&
          inputStr &&
          m?.name?.toLowerCase()?.includes(inputStr);
        return findMembers;
      }
    });
    if (foundMembers?.length) {
      setCurrentGroupMembers(foundMembers);
    } else if (!inputStr) {
      setGroupMembers();
    } else {
      setCurrentGroupMembers([]);
    }
  };
  return (
    <>
      {showMembers &&
      chatDetail?.groupData?.members?.length !== sendMessageTo?.length &&
      currentGroupMembers.length ? (
        <MembersList parentHeight={parentHeight}>
          {currentGroupMembers?.map(
            (member, i) =>
              !sendMessageTo.includes(member) && (
                <SelectMembers
                  key={i}
                  id={`member-item-${i}`}
                  className={`member-item`}
                  ref={memberRef}
                  onClick={(e) => {
                    selectMemberOnClick(e, member);
                  }}
                >
                  <img
                    src={
                      member?.picturePath ||
                      member?.profile ||
                      "/images/user.ico"
                    }
                    alt=""
                  />
                  <span>{member?.name}</span>
                </SelectMembers>
              )
          )}
        </MembersList>
      ) : (
        ""
      )}
      <SelectMembersContainer
        id="member-input"
        onClick={(e) => {
          e.stopPropagation();
          inputRef.current.focus();
          setShowMembers(true);
        }}
      >
        <SendToTxt>Send To :</SendToTxt>
        {sendMessageTo.map((el, i) => (
          <SelectedMember key={i} onClick={(e) => e.preventDefault()}>
            {el?.name}
            <GrFormClose
              id="remove-member"
              onClick={(e) => removeSelectedMember(e, el)}
            />
          </SelectedMember>
        ))}
        <div>
          <SelectMembersInput
            className="member-input"
            ref={inputRef}
            value={inputValue}
            inputValue={inputValue}
            onChange={filterMembers}
          />
        </div>
      </SelectMembersContainer>
    </>
  );
}

export default SelectSpecificMembers;
