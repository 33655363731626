import React, { useState, useCallback, useMemo } from "react";
import { Modal, Form, ListGroup, CloseButton, Button } from "react-bootstrap";
import styles from "./SmartChatList.module.css";

const SmartChatList = ({
  isOpen,
  onClose,
  onSave,
  onSetContacts,
  contacts,
  selectedContacts,
  setSelectedContacts,
  setSelectedCC,
  displayText,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectContact = useCallback(
    (contact) => {
      setSelectedContacts((prev) => new Set([...prev, contact]));
      onSetContacts((prev) => prev.filter((c) => c !== contact));
    },
    [setSelectedContacts, onSetContacts]
  );

  const handleRemoveContact = useCallback(
    (contact) => {
      const contactKey = `${contact.name}|${contact.email}|${contact.id}`;
      setSelectedCC((prevCc) => prevCc.filter((entry) => entry !== contactKey));
      setSelectedContacts((prev) => {
        const newSet = new Set(prev);
        newSet.delete(contact);
        return newSet;
      });
      onSetContacts((prev) => [...prev, contact]);
    },
    [setSelectedContacts, onSetContacts]
  );

  const handleSave = useCallback(() => {
    const formattedContacts = Array.from(selectedContacts).map(
      (contact) => `${contact.name}|${contact.email}|${contact.id}`
    );
    onSave(formattedContacts);
    onClose();
  }, [selectedContacts, onSave, onClose]);

  const filteredContacts = useMemo(
    () =>
      contacts.filter((contact) =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [contacts, searchTerm]
  );

  return (
    <Modal show={isOpen} onHide={onClose} size="md" centered>
      <Modal.Header
        closeButton
        className={`${styles["custom-bg-primary"]} text-white`}
      >
        <Modal.Title>Contacts</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Form.Control
          type="text"
          placeholder="Search"
          className="mb-3"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <h6>{displayText}</h6>
        <ListGroup>
          {Array.from(selectedContacts).map((contact, index) => (
            <ListGroup.Item key={index} className="d-flex align-items-center">
              <div
                className={`contact-avatar ${styles["custom-bg-primary"]} text-white rounded-circle d-flex align-items-center justify-content-center me-2`}
                style={{ width: 50, height: 50 }}
              >
                {contact.name.charAt(0)}
              </div>
              <div>
                <p className="mb-0">{contact.name}</p>
                <small>{contact.email}</small>
              </div>
              <CloseButton
                className="ms-auto text-danger"
                onClick={() => handleRemoveContact(contact)}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>

        <h6 className="mt-3">All Contacts</h6>
        <ListGroup style={{ maxHeight: "200px", overflowY: "auto" }}>
          {filteredContacts.map((contact, index) => (
            <ListGroup.Item
              key={index}
              className="d-flex align-items-center"
              onClick={() => handleSelectContact(contact)}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`contact-avatar ${styles["custom-bg-primary"]} text-white rounded-circle d-flex align-items-center justify-content-center me-2`}
                style={{ width: 50, height: 50 }}
              >
                {contact.name.charAt(0)}
              </div>
              <div>
                <p className="mb-0">{contact.name}</p>
                <small>{contact.email}</small>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className={styles.customPrimaryButton} onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SmartChatList;
