import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Welcome from "./components/welcomeScreen/Welcome/Welcome";

const App = () => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations.length > 0) {
          console.log("🗑️ Unregistering existing Service Worker...");
          registrations.forEach((registration) => registration.unregister());
        }

        // Register new service worker only if it's missing
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then(async (registration) => {
            console.log("✅ Service Worker Registered:", registration);

            if (registration.waiting) {
              console.log("⏳ Service Worker is waiting...");
              registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }

            return navigator.serviceWorker.ready;
          })
          .then((registration) => {
            console.log("🔥 Service Worker Ready:", registration);
          })
          .catch((error) => {
            console.error("❌ Service Worker Registration Failed:", error);
          });
      });
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
