import axios from "axios";

export const WEB_PUSH_CERTIFICATE =
  "BCfnUs5Bo5465fuR8Gbx23S8rT7DhqP7dZlTVTT6QLOP8sBesIG_BSLUEqrG0pmadNXym3zhTOn4K5ZA6gGURwM";
// export const API_URL = `${window.location.origin}/api`

export const COMMON_ERROR = "Error, Please try again.!";

/* 
      Please change favicon.ico if you change APP_LOGO and change title in index.html
      Also update icons in manifest.json file.
      and API_URL too.
 */

// QCall
// export const BASE_URL = "http://147.234.84.36:3000"
// export const API_URL = `${BASE_URL}/api/v1/web" `
// export const DATABASE = "QCall"
// export const WEB_ACCESS_TYPE = "QCall"
// export const APP_LOGO = "qcall-logo.png"

// Chat mail
// export const API_URL = "http://147.234.84.34:3000/api/v1/web"
// export const BASE_URL = "http://147.234.84.34:3000"
// export const BASE_URL = "https://apichatmail.tourtech.co.il";
export const BASE_URL = "https://api.asup.me";
export const URL_COMMON_PATH = `${BASE_URL}/api/v1`;
export const GROUP_API_URL = `${URL_COMMON_PATH}/group`;
export const ATTACHMENT_URL = `${BASE_URL}/attachments`;
export const API_URL = `${URL_COMMON_PATH}/web`; //For Web
export const CHAT_MAIL_CAMPAIGN_URL = `${URL_COMMON_PATH}/campaign`; //For Ad Campaign
export const STATIC_PAGE_URL = `${URL_COMMON_PATH}/staticpage`; //Static page
export const DATABASE = "web_chat_mail";
export const WEB_ACCESS_TYPE = "ASUP";
// export const APP_LOGO = "chat-mail-logo.ico";
export const APP_LOGO = "asup-logo.svg";

// ("https://apichatmail.tourtech.co.il/api/v1/staticpage/content?pageName=Web");

//For Web
export const serverAxios = axios.create({
  baseURL: API_URL,
});

//For Ad Campaign
export const chatMailAppServerAxios = axios.create({
  baseURL: CHAT_MAIL_CAMPAIGN_URL,
});

//For Group
export const chatMailGroupAxios = axios.create({
  baseURL: GROUP_API_URL,
});

//Static page
export const chatMailStaticAxios = axios.create({
  baseURL: STATIC_PAGE_URL,
});

const token = localStorage.getItem("token");
const AuthToken = token ? `Bearer ${token}` : "";
serverAxios.defaults.headers.Authorization = AuthToken;
