import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export const OTPMain = styled.form`
  position: relative !important;

  @media (max-width: 992px) {
    padding: 0rem !important;
  }
`;

export const OTPModal = styled(Modal)`
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 1.3rem;
    outline: 0;
    margin: 0 auto;

    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 575px) {
      width: 80%;
    }
    @media (max-width: 460px) {
      width: 100%;
    }
    .modal-body {
      @media (max-width: 392px) {
        padding-inline: 0.5rem;
      }
    }
  }
`;

export const TextOTP = styled.h3`
  color: #0a4d83;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;

  @media (max-width: 992px) {
    font-size: 28px !important;
  }
  @media (max-width: 524px) {
    font-size: 24px !important;
  }
  @media (max-width: 360px) {
    font-size: 20px !important;
  }
`;

export const ModalButtons = styled.div`
  position: absolute;
  top: -15px;
  right: 0;
  display: flex;
  align-items: center;

  @media (max-width: 360px) {
    svg {
      width: 36px;
      height: 36px;
      padding: 2px;
    }
  }
`;

export const CloseBtn = styled(Button)`
  border: none;
  box-shadow: none !important;
  background: transparent !important;
  color: #0a4d83;
  padding: 0;
  font-size: 40px;

  :hover {
    color: #2c4bb9;
  }
`;

export const Reset = styled.img.attrs({
  className: "mt-2 me-2",
  src: "./images/arrow-clockwise.svg",
})`
  border: none;
  box-shadow: none !important;
  background: transparent !important;
  color: #0a4d83;
  padding: 0;
  cursor: pointer;

  :hover {
    filter: brightness(85%);
  }

  @media (max-width: 360px) {
    margin-right: 0rem !important;
    margin-top: 10px !important;

    width: 14px;
    height: 14px;
  }
`;

export const OTPInput = styled.input`
  width: 50px;
  height: 50px;
  border: 1px solid #0a4d83;
  border-radius: 12px;
  outline: none;
  text-align: center;

  :hover {
    filter: brightness(90%);
  }
`;

export const HiddenInput = styled(OTPInput)`
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
`;
