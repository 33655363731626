import styled from "styled-components";

export const CampaignMain = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const Slider = styled.div`
  background-color: #0a4d83;
  color: white;
  text-align: center;
  cursor: pointer;
  svg {
    font-size: 1.6rem !important;
    transition: transform 0.5s;
    transform: ${(prop) => prop.showAds && "rotate(180deg)"};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const AdListContainer = styled.div`
  transition: height 0.5s;
  background-color: #fff;
  ${(props) =>
    props.showAds
      ? `height: 360px;
  overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    `
      : "height: 0;"}
`;

export const SVGContainer = styled.div`
  transition: transform 0.5s;
`;
