import styled from "styled-components";

export const BodyBG = styled.div.attrs({
  className: "d-flex justify-content-center",
})`
  background: linear-gradient(
    180deg,
    rgba(239, 246, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  backdrop-filter: blur(80px);
  height: 100vh;
`;

export const UseWebVersionButton = styled.button`
  background: #0a4d83;
  box-shadow: 0px 2px 6px rgb(27 67 123 / 24%);
  border-radius: 12px;
  padding: 14px 16px;
  width: 240px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1rem;
  border: 1px solid #0d6efd;
  color: #fff;
`;

export const WelcomeMain = styled.div`
  max-width: 100%;
  width: 650px;
  text-align: center;
  margin-top: 120px;
  padding: 0 15px;
`;

export const AppStoreButtons = styled.div.attrs({
  className: "d-flex align-items-center gap-3 justify-content-center mt-2",
})``;

export const PlayStore = styled.img.attrs({
  src: `./images/ic_play_store.ico`,
})``;

export const AppleStore = styled.img.attrs({
  src: `./images/ic_app_store.ico`,
})``;

export const WelcomeHeading = styled.h2`
  color: #0a4d83;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;

  @media (max-width: 425px) {
    font-size: 28px !important;
  }
`;

export const WelcomePara = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 1.5rem !important;
`;

export const TextUseWeb = styled.span`
  color: #0a4d83;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const TextReadMessage = styled.h1`
  color: #0a4d83;
  font-weight: 600;
`;

export const TextDownload = styled.h6`
  margin-top: 1.5rem !important;
  color: #0a4d83;
  font-weight: 600;
  font-size: 18px;
`;

export const ORTextContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 250px;
  display: flex;
  align-items: center;
  gap: 1rem !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  color: #9eaabd;
`;

export const Line = styled.div`
  background-color: #d5e0e9;
  height: 1px;
  width: 100%;
`;

export const LowerDiv = styled.div`
  margin-top: 70px;
`;
