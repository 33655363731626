// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { disableNetwork, getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { WEB_PUSH_CERTIFICATE } from "../config";
import { isIOS } from "../../components/CommonThings/CommonThings";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBOxmXvNFiLuwii9ZCMFiKLaZvr5n9P4zo",
  authDomain: "chatmail-b272e.firebaseapp.com",
  projectId: "chatmail-b272e",
  storageBucket: "chatmail-b272e.appspot.com",
  messagingSenderId: "245807166112",
  appId: "1:245807166112:web:a45f485aa0f17fbf618fc5",
  measurementId: "G-6K83BQZYNT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const fireStoreDB = getFirestore(app);

export const onMessageListener = (setFirebaseForegroundMessage) => {
  onMessage(messaging, (payload) => {
    console.log("payload", payload);
    setFirebaseForegroundMessage(payload);
  });
};

export const tokenFromFirebase = async () => {
  try {
    console.log("⏳ Checking service worker state...");

    // Ensure service worker is registered
    const registration = await navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .catch((err) => {
        console.error("❌ Service Worker Registration Failed:", err);
        throw err;
      });

    console.log("✅ Service Worker Registered:", registration);

    // Ensure Service Worker is Controlling the Page
    if (!navigator.serviceWorker.controller) {
      console.log("⚠️ Service Worker not controlling the page... waiting...");
      await new Promise((resolve) => {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          console.log("🔄 Service Worker is now controlling the page!");
          resolve();
        });
      });
    }

    console.log("📌 Checking Push Subscription...");
    let pushSubscription = await registration.pushManager.getSubscription();

    if (pushSubscription) {
      console.log("📌 Existing Push Subscription Found, Unsubscribing...");
      await pushSubscription.unsubscribe();
    }

    console.log("📌 Fetching Firebase token...");
    const tokenResponse = await getToken(getMessaging(), {
      vapidKey: WEB_PUSH_CERTIFICATE,
      serviceWorkerRegistration: registration,
    });

    if (tokenResponse) {
      console.log("🎉 Token received from Firebase:", tokenResponse);
      return {
        status: 1,
        message: "Token received from Firebase.",
        data: { token: tokenResponse },
      };
    } else {
      console.warn("⚠️ No registration token available.");
      return {
        status: 0,
        message:
          "No registration token available. Request permission to generate one.",
      };
    }
  } catch (error) {
    console.error("❌ Error retrieving token:", error);

    // If the error is related to storage, try to recover
    if (error.message.includes("storage error")) {
      console.log("🔄 Attempting to recover from storage error...");

      await navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((reg) => reg.unregister());
      });

      console.log("✅ Service Worker Cleared. Retrying token retrieval...");

      return tokenFromFirebase(); // Retry fetching the token without reloading
    }

    return {
      status: 0,
      message: "Unable to get registration token.",
      error,
    };
  }
};
