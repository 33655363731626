import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { useState, useEffect, useRef, createContext } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createChat,
  createGroupChat,
  deleteChatMessage,
  getAllChats,
  getAllGroupChats,
  getAllGroups,
  logout,
  updateChat,
  updateGroup,
  updateGroupChat,
  updateUser,
} from "../../IndexedDB/queries";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { IoDocumentTextOutline } from "react-icons/io5";

import {
  getGroupInfo,
  getPendingInvite,
  logoutFromServer,
  saveDeviceToken,
  sendMessageToAppUser,
  sendMessageToGroupAPI,
} from "../../Auth/api";
import WebcamCapture from "./cameraImage/WebcamCapture";
import {
  onMessageListener,
  tokenFromFirebase,
} from "../../Auth/firebase/firebaseConnection";
import {
  fireStoreUpdateDoc,
  fireStoreGetActivity,
  getTypingUsers,
} from "../../Auth/firebase/firebaseQueries";
import { IoIosClose } from "react-icons/io";
import AudioElement from "./audioElement/AudioElement";
import {
  addMassagesAndInvitationInIndexedDB,
  getCommonInvitation,
  highlightedLog,
  isIOS,
  clearSiteDataFn,
} from "../CommonThings/CommonThings";
import { AiOutlineAudio } from "react-icons/ai";
import ChatBox from "./chatBox/ChatBox";
import { BsCamera } from "react-icons/bs";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import PermissionInstructions from "./PermissionInstructions/PermissionInstructions";
import Wavesurfer from "./WaveSurfer/Wavesurfer";
import {
  AddFiles,
  OnlineStatus,
  ProfileImage,
  ProfileImageContainer,
  UserTyping,
  XYCenteredContent,
} from "../CommonThings/common.styled";
import {
  ActionsDropDown,
  CancelButton,
  CancelImage,
  MultiUserImage,
  UserImage,
  SendIcon,
  MicIcon,
  ContentBarRight,
  CCImage,
  BCCImage,
  ChatBoxWrapper,
  ChatDetail,
  ChatDetailWrap,
  ChatHeader,
  ChatMain,
  ClearChat,
  CloseContentBar,
  ContentBarMain,
  ContentBarWrap,
  ContentToBeSent,
  MessageInputWrapper,
  NameDesc,
  NotificationBody,
  NotificationTitle,
  ReplyImage,
  SelectFiles,
  SelectMultiple,
  SelectedData,
  SidebarToggle,
  UserActionButton,
  UserActions,
  IconsContainer,
} from "./chat.styled";
import { HiUsers } from "react-icons/hi";
import SelectSpecificMembers from "./SelectSpecificMembers/SelectSpecificMembers";
import CMLoader from "./Loader/CMLoader";
import SmartChatList from "./chatModal/SmartChatList";
import { sortChatList } from "../CommonThings/CommonThings";

const Chat = ({ logoutFromChat }) => {
  const navigate = useNavigate();

  const imageType = [
    "jpg",
    "jpeg",
    "gif",
    "png",
    "tif",
    "tiff",
    "bmp",
    "ico",
    "x-icon",
  ];
  const videoType = ["mp4", "avi", "wmv", "avc"];
  const documentType = [
    "txt",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "pdf",
  ];
  const voiceType = ["mp3", "ogg", "wma", "aac", "wav", "mpeg"];

  const messagesEndRef = useRef(null);
  const sidebarRef = useRef(null);
  const messageref = useRef(null);

  const [notificationPermission, setNotificationPermission] = useState(0);

  const [sidebar, setSidebar] = useState();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(false);
  // const [recordStart, setRecordStart] = useState(false);
  const [chatDetail, setChatDetail] = useState({});
  const [senderID, setSenderID] = useState("");
  const [groupID, setGroupID] = useState("");
  const [message, setMessage] = useState("");
  const [userChat, setUserChat] = useState([]);
  const [photoOrVideo, setPhotoOrVideo] = useState(null);
  const [fileDocument, setFileDocument] = useState(null);
  const [subject, setSubject] = useState("");
  const [showSubject, setShowSubject] = useState(false);
  const [sendToMembers, setSendToMembers] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCCModalOpen, setIsCCModalOpen] = useState(false);
  const [isToModalOpen, setIsToModalOpen] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [showTo, setShowTo] = useState(false);
  const [cc, setCc] = useState([]);
  const [rootContacts, setRootContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState(new Set());
  const [bcc, setBcc] = useState([]);
  const [bccContacts, setBccContacts] = useState([]);
  const [selectedBccContacts, setSelectedBccContacts] = useState(new Set());
  const [to, setTo] = useState([]);
  const [toContacts, setToContacts] = useState([]);
  const [selectedToContacts, setSelectedToContacts] = useState(new Set());

  const removeContact = (
    index,
    fieldSetter,
    selectedContacts,
    setContacts,
    setSelectedContacts
  ) => {
    fieldSetter((prev) => {
      const removedEntry = prev[index];
      const updatedList = prev.filter((_, i) => i !== index);

      if (removedEntry.includes("|")) {
        const [, email] = removedEntry.split("|");
        const removedContact = [...selectedContacts].find(
          (c) => c.email === email
        );

        if (removedContact) {
          setContacts((prevContacts) => [...prevContacts, removedContact]);
          setSelectedContacts((prev) => {
            const newSet = new Set(prev);
            newSet.delete(removedContact);
            return newSet;
          });
        }
      }

      return updatedList;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sortedChats = await sortChatList();
        const formattedContacts = sortedChats.map((chat) => ({
          id: chat.userData.SenderID,
          name: chat.userData.SenderName,
          email: chat.userData.senderPhonewithCcode,
          profilePic: chat.userData.Profilepic,
        }));

        setContacts(formattedContacts);
        setBccContacts(formattedContacts);
        setToContacts(formattedContacts);
        setRootContacts(formattedContacts);
      } catch (error) {
        console.error("Errorf fetching chat list:", error);
      }
    };

    fetchData();
  }, []);

  const handleSaveContacts = (selectedContacts) => {
    setCc((prevCc) => {
      const newEntries = selectedContacts.filter(
        (contact) => !prevCc.includes(contact)
      );
      return [...prevCc, ...newEntries];
    });
  };

  const handleBccSaveContacts = (selectedBccContacts) => {
    setBcc((prevCc) => {
      const newEntries = selectedBccContacts.filter(
        (contact) => !prevCc.includes(contact)
      );
      return [...prevCc, ...newEntries];
    });
  };

  const handleToSaveContacts = (selectedToContacts) => {
    setTo((prevCc) => {
      const newEntries = selectedToContacts.filter(
        (contact) => !prevCc.includes(contact)
      );
      return [...prevCc, ...newEntries];
    });
  };

  const handleKeyDown = (e, fieldSetter) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const email = e.target.value.trim();
      if (email) {
        fieldSetter((prev) => [...prev, email]);
        e.target.value = "";
      }
    }
  };

  //for Group Chat
  const [groupChatMessages, setGroupChatMessages] = useState([]);
  const [specificMembers, setSpecificMembers] = useState([]);
  const [groupTyping, setGroupTyping] = useState({
    groupID: "",
    typing_str: "",
  });

  // const [cameraPicture, setCameraPicture] = useState({})
  const [show, setShow] = useState(false);

  const [inputType, setInputType] = useState("message"); // message, photoOrVideo, document, camera, voice

  // mic record objects
  const [recordDetail, setRecordDetail] = useState({});
  const [recordDuration, setRecordDuration] = useState({});
  const [voice, setVoice] = useState(null);

  // reply thing
  const [reply, setReply] = useState({});

  //delete message
  const [select, setSelect] = useState(false);

  const [contactID, setContactID] = useState("");
  const [userTyping, setUserTyping] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [senderActivity, setSenderActivity] = useState({});
  const [firebaseForegroundMessage, setFirebaseForegroundMessage] =
    useState(null);
  const [iOSDevice, setiOSDevice] = useState("");
  const [notificationPermissionState, setNotificationPermissionState] =
    useState("");

  const searchMode = localStorage.getItem("search");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    isIOS(setiOSDevice);
    userBackOnlineHandler();
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        const payload = event.data;
        updateWebWithReceivedNotifications(payload);
      });
    }
  }, []);

  // Call saveDeviceToken API once, from web and set firebase token.
  //can get token only after the sw is active
  useEffect(() => {
    if (contactID?.trim() && iOSDevice == "NO") {
      initFirebaseMessaging();
    }
  }, [contactID, iOSDevice, notificationPermission]);

  // read foreground messages
  useEffect(() => {
    if (firebaseForegroundMessage) {
      foregroundMessageAndInvitationFunction();
    }
  }, [firebaseForegroundMessage]);

  const initFirebaseMessaging = () => {
    if (Notification.permission === "granted") {
      setNotificationPermissionState("granted");
      getFirebaseToken(contactID);
      onMessageListener(setFirebaseForegroundMessage);
      callPendingInviteAPI(contactID);
    } else {
      let permissionGranted;
      permissionGranted = window.confirm("Allow notification permission ?");
      if (permissionGranted) {
        Notification.requestPermission().then(() => {
          if (Notification.permission === "granted") {
            setNotificationPermissionState("granted");
            toast.success("Please wait until the page is reloaded.");
          }
        });
        getFirebaseToken(contactID).then(() => {
          Notification.permission === "granted" && window.location.reload();
        });
      } else {
        setNotificationPermission((prev) => prev + 1);
        Notification.requestPermission();
        toast.warn(
          "Please allow notification permission for better experience."
        );
      }
    }
  };

  const callPendingInviteAPI = async (contactID) => {
    const response = await getPendingInvite({ contactId: contactID });
    if (response?.status === 200) {
      response?.data?.users?.map(async (sender) => {
        const senderFormat = {
          profilePic: sender.Profilepic,
          senderNumber: sender.SenderNumber,
          recipientUserID: Number(sender.SenderID),
          sendingUserID: Number(sender.contactID),
          title: sender.SenderName,
          webID: Number(sender.webID),
          type: 2,
        };
        await getCommonInvitation(senderFormat, sidebarRef);
      });
      response?.data?.groups?.map(async (group) => {
        await getCommonInvitation(group, sidebarRef);
      });

      setTimeout(() => {
        sidebarRef.current.fetchAllChats(
          chatDetail?.userData?.webID || null,
          chatDetail?.groupData?.groupID || null
        );
      }, 200);
    }
  };

  const getGroupInfoLatest = async (contact_id, groupID) => {
    const latestGroupInfo = await getGroupInfo({
      sessionId: Number(contact_id),
      groupId: Number(groupID),
    });
    console.log("latestGroupInfo", latestGroupInfo);

    if (latestGroupInfo.status == 200) {
      const { data } = latestGroupInfo;
      const allGroups = await getAllGroups();
      let updated_group = null;

      if (allGroups.status == 1) {
        const foundGroup = await allGroups.data.find(
          (group) => group?.groupData?.groupID == data?.ID
        );

        if (foundGroup) {
          updated_group = foundGroup;
          var rgb = [];

          data.members.unshift(data.owner);

          updated_group.groupData.members.forEach((m) => {
            var foundMember = data.members.find(
              (member) => member.ID == m.ID || member.ID == m.id
            );

            if (foundMember && !foundMember.color) {
              if (m.color) {
                foundMember.color = m.color;
              } else {
                for (var i = 0; i < 3; i++) {
                  rgb.push(Math.floor(Math.random() * 255));
                }
                foundMember.color = `rgb(${rgb.join(",")})`;
                rgb = [];
              }
            }
          });

          updated_group.groupData.members = data.members;
          updated_group.groupData.groupDescription = data.description;
          updated_group.groupData.groupName = data.name;
          updated_group.groupData.profilePic = data.profilePic;

          const updated_info = await updateGroup(updated_group);

          return;
        }
      }
    }
  };

  const Msg = ({ title, body }) => {
    return (
      <div>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationBody>{body}</NotificationBody>
      </div>
    );
  };

  const foregroundMessageAndInvitationFunction = async () => {
    const payload = firebaseForegroundMessage;
    setFirebaseForegroundMessage(null);

    // user send invitation and add in user table
    if (payload.notification) {
      toast.success(
        <Msg
          title={payload.notification.title}
          body={payload.notification.body}
        />
      );
    }
    await updateWebWithReceivedNotifications(payload.data);
  };

  const updateWebWithReceivedNotifications = async (payload_data) => {
    const receivedMessage = await addMassagesAndInvitationInIndexedDB(
      payload_data,
      sidebarRef,
      userChat,
      senderID || groupID,
      contactID
    );

    if (receivedMessage && !chatDetail?.is_ad) {
      if (
        receivedMessage?.api_response?.groupID == chatDetail?.groupData?.groupID
      ) {
        setGroupChatMessages([...groupChatMessages, receivedMessage]);
      } else {
        Number(chatDetail.userData?.SenderID) ===
          Number(receivedMessage.api_response?.recipientUserID) &&
          setUserChat([...userChat, receivedMessage]);
      }
    }

    let contact_id =
      contactID ||
      payload_data.contactId ||
      payload_data.sendingUserID ||
      chatDetail?.groupData?.contactID ||
      chatDetail?.userData?.contactID;
    if (contact_id) {
      //Get group's latest details
      if (payload_data.groupId || payload_data.groupID) {
        await getGroupInfoLatest(
          contact_id,
          payload_data.groupId || payload_data?.groupID
        );
      }
      await callPendingInviteAPI(contact_id);
    }
  };

  useEffect(() => {
    if (chatDetail?.groupData) {
      setIsOnline(false);
      senderActivity?.receptionid == chatDetail?.groupData?.groupID &&
        getAllTypingMembers();
    }
    if (senderActivity?.uid == chatDetail?.userData?.SenderID) {
      setIsOnline(senderActivity?.islive === "active");
      setIsTyping(
        senderActivity?.receptionid == chatDetail?.userData?.contactID &&
          senderActivity?.istyping
      );
    }
  }, [senderActivity, chatDetail]);

  const getAllTypingMembers = async () => {
    const typingMembers = await getTypingUsers(chatDetail?.groupData?.groupID);
    let final_arr = typingMembers.filter(
      (doc) => doc.uid != chatDetail?.groupData?.contactID
    );
    final_arr = final_arr.map(
      (doc) =>
        chatDetail?.groupData?.members?.find((m) => m?.ID == doc.uid)?.name
    );
    if (!final_arr?.length) {
      setGroupTyping({
        groupID: chatDetail?.groupData?.groupID,
        typing_str: "",
      });
    } else if (final_arr?.length == 1) {
      setGroupTyping({
        groupID: chatDetail?.groupData?.groupID,
        typing_str: `${final_arr?.join()} is typing . . .`,
      });
    } else if (final_arr?.length == 2) {
      setGroupTyping({
        groupID: chatDetail?.groupData?.groupID,
        typing_str: `${final_arr?.join(" and ")} are typing . . .`,
      });
    } else if (final_arr?.length > 2) {
      setGroupTyping({
        groupID: chatDetail?.groupData?.groupID,
        typing_str: `${final_arr?.length} people are typing . . .`,
      });
    }
  };

  const getChatDetail = async (selected_chat) => {
    const chat_data = { ...selected_chat, unReadCount: 0 };
    setChatDetail(chat_data);
    setContactID(
      selected_chat.userData?.contactID?.toString() ??
        selected_chat.groupData?.contactID?.toString() ??
        ""
    );

    if (selected_chat.userData) {
      await updateUser(chat_data);
      !selected_chat?.is_ad &&
        setSenderID(Number(selected_chat.userData?.SenderID));

      // functions
      fireStoreGetActivity(selected_chat.userData?.SenderID, setSenderActivity);

      !selected_chat?.is_ad &&
        getChatMessagesAndDetails(
          selected_chat.userData?.contactID?.toString(),
          selected_chat.userData?.SenderID?.toString()
        );
      setGroupID("");
    } else if (selected_chat.groupData) {
      await updateGroup(chat_data);
      setGroupID(Number(selected_chat.groupData?.groupID));
      setSenderID("");
      selected_chat?.groupData?.members.forEach((member) =>
        fireStoreGetActivity(member?.ID, setSenderActivity)
      );
    }
  };

  const getAdcampaign = async (e, ad) => {
    getChatDetail(ad);
    setChatDetail(ad);
    setContactID(ad.contactID?.toString() ?? "");
    setUserChat(ad.banner);
  };

  const getChatMessagesAndDetails = async (contact_id, sender_id) => {
    const getAllChatResponse = await getAllChats();

    if (getAllChatResponse.status === 1) {
      if (getAllChatResponse.data.length) {
        const senderMessages = getAllChatResponse.data.filter(
          (data) =>
            Number(data.api_response?.recipientUserID) === Number(sender_id) &&
            Number(data.api_response.sendingUserID) === Number(contact_id)
        );
        setUserChat([...senderMessages]);
      } else {
        setUserChat([]);
      }
    }
  };

  // get client token from firebase
  const getFirebaseToken = async (contactID) => {
    setLoading(true);
    const reloadAfter = 15 * 1000;
    const timeoutId = setTimeout(() => {
      if (
        navigator?.serviceWorker?.controller?.state !== "activated" &&
        Notification.permission === "granted"
      ) {
        window.location.reload();
      } else {
        getFirebaseToken(contactID);
      }
    }, reloadAfter);
    const response = await tokenFromFirebase();
    if (response?.status === 1) {
      clearTimeout(timeoutId);
      const requestData = {
        contactId: Number(contactID),
        deviceToken: response.data.token,
      };
      const deviceTokenResponse = await saveDeviceToken(requestData);
      if (deviceTokenResponse?.status === 200) {
        highlightedLog("Firebase Token", response.data.token);
        fireStoreUserUpdate(contactID, { islive: "active" });
      }
    } else {
      if (
        navigator?.serviceWorker?.controller?.state !== "activated" &&
        Notification.permission === "granted"
      ) {
        setLoading(true);
        toast.info(
          "Reloading the page to apply configurations. Please wait...",
          {
            autoClose: reloadAfter,
          }
        );
      }
      toast.error(response.message);
    }
    setLoading(false);
  };
  const fireStoreUserUpdate = async (contactID, data) => {
    const response = await fireStoreUpdateDoc(contactID, data); // inactive
    if (response?.status === 1) {
    } else {
      toast.error(response?.message);
    }
  };

  const sendUndeliveredMessages = async () => {
    console.log("sending undelivered messages...");
    const all_messages = await getAllChats();
    const undelivered_messages = all_messages.data.filter(
      (chat) => chat.delivery_status === "undelivered"
    );

    if (undelivered_messages.length) {
      let delivered_messages = await undelivered_messages.map(
        async (message) => {
          const resendMessageForm = new FormData();

          let msgReq = message.api_response;

          if (msgReq.groupID) {
            resendMessageForm.append("groupID", msgReq.groupID);
            resendMessageForm.append("sessionId", msgReq.sessionId);
            resendMessageForm.append("previewFiles", "yes");
          } else {
            resendMessageForm.append("chatID", msgReq.chatID);
            resendMessageForm.append("webID", msgReq.webID);
            resendMessageForm.append("contactID", msgReq.contactID);
            resendMessageForm.append("recipientUserID", msgReq.recipientUserID);
          }

          resendMessageForm.append("typeID", msgReq.typeID);
          resendMessageForm.append("Body", msgReq.Body);
          resendMessageForm.append("subject", msgReq.subject);
          resendMessageForm.append("uniqueMessageID", Date.now());
          resendMessageForm.append(
            "isReply",
            Number(msgReq.repliedMessageID) ? 1 : 0
          );
          resendMessageForm.append(
            "repliedMessageID",
            Number(msgReq.repliedMessageID) || 0
          );

          if (msgReq.fileTypes) {
            resendMessageForm.append("files", msgReq.files);
            resendMessageForm.append("fileTypes", msgReq.fileTypes);
          }
          let resendResponseRaw = msgReq.groupID
            ? await sendMessageToGroupAPI(resendMessageForm)
            : await sendMessageToAppUser(resendMessageForm);

          let resendResponse;
          try {
            const responseStr =
              typeof resendResponseRaw === "string"
                ? resendResponseRaw
                : JSON.stringify(resendResponseRaw);
            const cleanedStr = responseStr
              .replace(/\{\s*"name":\s*"[^"]*"\s*\},?/g, "")
              .trim();
            resendResponse = JSON.parse(cleanedStr);
          } catch (error) {
            console.error("Error parsing response:", error);
          }
          if (resendResponse.status == 200) {
            const updated_message = {
              ...message,
              api_response: { ...resendResponse.data, groupID: msgReq.groupID },
              delivery_status: "done",
              status: true,
            };
            await updateChat(updated_message);

            return updated_message;
          } else {
            console.error("API error", resendResponse);
            if (
              resendResponse.status === 440 ||
              resendResponse.status === 400 ||
              resendResponse.status === 401
            ) {
              const deletedResponse = await deleteChatMessage({
                msg_id: message.id,
                chatDetail,
              });
            } else {
              console.error(
                resendResponse?.message
                  ? resendResponse.message
                  : "Something went wrong !"
              );
            }
          }
        }
      );
      delivered_messages = await Promise.all(delivered_messages).then(
        (value) => value
      );
      console.log("delivered_messages", delivered_messages);
      return delivered_messages;
    }
  };

  const userBackOnlineHandler = () => {
    window.addEventListener("online", async () => {
      const data = await sendUndeliveredMessages(chatDetail);
      const lastMessage = data[data.length - 1];
      if (data.length) {
        await sidebarRef.current.addLastMessageInUserList(
          lastMessage,
          lastMessage.groupID || lastMessage.api_response.recipientUserID
        );
        await sidebarRef.current.fetchAllChats(
          chatDetail?.userData?.webID || null,
          chatDetail?.groupData?.groupID || null
        );
      }
    });
  };

  useEffect(() => {
    updateFirebseUserAndTypingStatus();
  }, [message, photoOrVideo, fileDocument, voice, recordDetail]);

  const updateFirebseUserAndTypingStatus = () => {
    if (
      message ||
      photoOrVideo?.name ||
      fileDocument?.name ||
      voice?.name ||
      recordDetail?.blobURL
    ) {
      if (!userTyping) {
        setUserTyping(true);
        fireStoreUserUpdate(contactID, {
          istyping: true,
          receptionid: Number(
            chatDetail?.userData?.SenderID || chatDetail?.groupData?.groupID
          ),
        });
      }
    } else {
      if (userTyping) {
        setUserTyping(false);
        fireStoreUserUpdate(contactID, { istyping: false });
      }
    }
  };

  const scrollToBottom = () => {
    if (searchMode === "true" && chatDetail.highlight === "message") {
      messageref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      messagesEndRef.current.scrollTop =
        !chatDetail?.is_ad && messagesEndRef.current.scrollHeight;
    }
  };

  //Scroll to bottom, when message length updated
  useEffect(scrollToBottom, [userChat, groupChatMessages]);

  useEffect(() => {
    if (chatDetail?.groupData) {
      get_group_chat_messages(chatDetail);
    }
    console.log("chatDetail", chatDetail, "\ncontactID", contactID);
  }, [chatDetail]);

  const callLogoutAPI = async (e, logout_type) => {
    if (logout_type === "complete") {
      const logoutUserResponse = await logout({
        contact_id: Number(contactID),
      });
      if (logoutUserResponse.status === 1) {
        const logoutResponse = await logoutFromServer({
          contactId: contactID,
        });
        toast.success("User Logout.");
        logoutFromChat();
        fireStoreUserUpdate(contactID, { islive: "inactive", istyping: false });
        navigate("/");
      } else {
        console.error("logout_error", logoutUserResponse);
      }

      // const
    } else {
      try {
        const logoutResponse = await logoutFromServer({
          // contactId: contactID,
          webID: chatDetail && chatDetail.userData.webID,
        });
      } catch (error) {
        console.log("partialLogoutError", error);
      }
    }
  };

  /*
    1 - text - null;
    2 - voice - mp3, ogg, wma, aac, wav;
    3 - video - mp4, avi, wmv, avc;
    4 - image - jpg, jpeg, gif, png, tif, tiff, bmp, ico;
    5 - document - txt, doc, docx, xls, xlsx, ppt, pptx, pdf;
  */

  const clearMessageInput = async () => {
    setReply({});
    setMessage("");
    setSubject("");
    setShowSubject(false);
    setShowTo(false);
    setShowCC(false);
    setShowBCC(false);
    setTo([]);
    setCc([]);
    setBcc([]);
    setContacts(rootContacts);
    setSelectedContacts(new Set());
    setBccContacts(rootContacts);
    setSelectedBccContacts(new Set());
    setToContacts(rootContacts);
    setSelectedToContacts(new Set());
    setPhotoOrVideo({});
    setFileDocument({});
    setInputType("message");
    setRecordDetail({});
    setVoice({});
    setRecord(false);
    setSendToMembers(false);
    // setShowSubject(!showSubject);
  };

  //common function for sending message to user or group
  const sendMessageToSenderOrGroup = async (e) => {
    e.preventDefault();

    let typeID = 1;
    let body = message.trim() || "";
    if (
      voiceType.includes(voice?.type?.split("/")[1]) ||
      voiceType.includes(recordDetail?.audioFile?.type?.split("/")[1])
    ) {
      typeID = 2;
    }
    if (videoType.includes(photoOrVideo?.type?.split("/")[1])) {
      typeID = 3;
    }
    if (imageType.includes(photoOrVideo?.type?.split("/")[1])) {
      typeID = 4;
    }

    if (documentType.includes(fileDocument?.name?.split(".")[1])) {
      typeID = 5;
    }

    if (typeID === 1 && body.trim() === "") {
      return;
    }

    const requestData = {
      role: "user",
      status: false,
      currentTime: Date.now(),
      api_response: {
        groupID: chatDetail?.groupData && chatDetail.groupData.groupID,
        body: body,
        typeID: typeID,
        subject: subject.trim() || "",
        toEmailList: to?.map((item) => item.trim()) || [],
        ccEmailList: cc?.map((item) => item.trim()) || [],
        bccEmailList: bcc?.map((item) => item.trim()) || [],
      },
    };

    if (reply?.id) {
      requestData.reply = reply;
    }

    // for chat with individual user
    let chatArray;
    if (chatDetail?.userData && navigator.onLine) {
      chatArray = userChat;
      userChat.push(requestData);
      setUserChat([...userChat]);
    }

    const save_sent_message = await createChat(requestData);

    if (save_sent_message.status === 1) {
      const id = save_sent_message.data;
      const requestFormData = new FormData();

      if (chatDetail?.userData) {
        const { webID, contactID } = chatDetail.userData;

        requestFormData.append("chatID", id);
        requestFormData.append("webID", webID);
        requestFormData.append("contactID", contactID);
        requestFormData.append("recipientUserID", senderID);
      } else {
        //for Groups
        const groupMessageData = {
          group_id: chatDetail.groupData.groupID,
          chat_ids: [id],
        };
        //find group chat in IndexedDB
        const groupChatData = await getAllGroupChats();
        if (groupChatData.status === 1) {
          //check if group chat exists
          const found_data = groupChatData.data.find(
            (gc) => gc.group_id === chatDetail.groupData.groupID
          );
          if (found_data) {
            //update chat_ids array
            groupMessageData.id = found_data.id;
            found_data.chat_ids.push(id);
            groupMessageData.chat_ids = found_data.chat_ids;

            const updateGroupChatData = await updateGroupChat(groupMessageData);
          } else {
            //create groupChat
            const saveToGroupChat = await createGroupChat(groupMessageData);
            if (saveToGroupChat.status === 1) {
              console.log("New GroupChat started");
            } else {
              console.error("saveToGroupChatERROR", saveToGroupChat);
            }
          }
        } else {
          console.error("groupChatDataERROR", groupChatData);
        }
        groupChatMessages.push(requestData);
        setGroupChatMessages(groupChatMessages);
        const { groupID, contactID } = chatDetail.groupData;

        requestFormData.append("groupID", groupID);
        requestFormData.append("sessionId", contactID);
        requestFormData.append("previewFiles", "yes");

        if (specificMembers.length) {
          specificMembers.forEach((member, i) => {
            requestFormData.append(
              `specificmemberlist[${i}][user]`,
              member.existence
                ? member.ID
                : member.email || `${member.phoneWithCcode}`
            );
          });
        }
      }

      requestFormData.append("typeID", requestData.api_response.typeID);
      requestFormData.append("Body", requestData.api_response.body);
      requestFormData.append("subject", requestData.api_response.subject);

      // Function to transform email lists
      const transformEmailList = (emailList, userType) =>
        Array.isArray(emailList) && emailList.length > 0
          ? emailList.map((item) => {
              if (item.includes("|")) {
                const [name, number, recipientUserID] = item.split("|");
                return {
                  number,
                  userType,
                  recipientUserID,
                };
              } else {
                return { email: item, userType, recipientUserID: "" };
              }
            })
          : [];

      // for to, cc and bcc
      const toEmailList = requestData?.api_response?.toEmailList ?? [];
      const finalToEmailList = transformEmailList(toEmailList, "1");
      requestFormData.append("toEmailList", JSON.stringify(finalToEmailList));

      const ccEmailList = requestData?.api_response?.ccEmailList ?? [];
      const finalCcEmailList = transformEmailList(ccEmailList, "2");
      requestFormData.append("ccEmailList", JSON.stringify(finalCcEmailList));

      const bccEmailList = requestData?.api_response?.bccEmailList ?? [];
      const finalBccEmailList = transformEmailList(bccEmailList, "3");
      requestFormData.append("bccEmailList", JSON.stringify(finalBccEmailList));

      requestFormData.append("uniqueMessageID", Date.now());
      requestFormData.append(
        "isReply",
        reply?.api_response?.uniqueMessageID ? 1 : 0
      );
      requestFormData.append(
        "repliedMessageID",
        reply?.api_response?.uniqueMessageID || 0
      );
      // requestFormData.append("extraData", 5);

      if (voiceType.includes(voice?.type?.split("/")[1])) {
        requestFormData.append("files", voice);
        requestFormData.append("fileTypes", voice.name.split(".")[1]);
      }

      if (voiceType.includes(recordDetail?.audioFile?.type?.split("/")[1])) {
        requestFormData.append("files", recordDetail?.audioFile);
        requestFormData.append(
          "fileTypes",
          recordDetail?.audioFile.name.split(".")[1]
        );
      }

      if (imageType.includes(photoOrVideo?.type?.split("/")[1])) {
        requestFormData.append("files", photoOrVideo);
        requestFormData.append("fileTypes", photoOrVideo.type.split("/")[1]);
      }

      if (videoType.includes(photoOrVideo?.type?.split("/")[1])) {
        requestFormData.append("files", photoOrVideo);
        requestFormData.append("fileTypes", photoOrVideo.type.split("/")[1]);
      }

      if (documentType.includes(fileDocument?.name?.split(".")[1])) {
        requestFormData.append("files", fileDocument);
        requestFormData.append("fileTypes", fileDocument.name.split(".")[1]);
      }
      await clearMessageInput();

      //If navigator is not onLine
      if (!navigator.onLine) {
        var message_data = {};

        for (var key of requestFormData.keys()) {
          message_data[key] = requestFormData.get(key);
        }
        const updateResponse = await updateChat({
          ...requestData,
          id,
          api_response: {
            ...message_data,
            groupID: chatDetail?.groupData && chatDetail.groupData.groupID,
          },
          delivery_status: "undelivered",
        });
        userChat.push({
          ...requestData,
          id,
          api_response: {
            ...message_data,
            groupID: chatDetail?.groupData && chatDetail.groupData.groupID,
          },
          delivery_status: "undelivered",
        });
        setUserChat([...userChat]);
        return;
      }

      const sentMessageRawResponse = chatDetail?.groupData
        ? await sendMessageToGroupAPI(requestFormData)
        : await sendMessageToAppUser(requestFormData);

      let sentMessageResponse;
      try {
        const responseStr =
          typeof sentMessageRawResponse === "string"
            ? sentMessageRawResponse
            : JSON.stringify(sentMessageRawResponse);
        const cleanedStr = responseStr
          .replace(/\{\s*"name":\s*"[^"]*"\s*\},?/g, "")
          .trim();
        sentMessageResponse = JSON.parse(cleanedStr);
      } catch (error) {
        console.error("Error parsing response:", error);
      }
      if (sentMessageResponse.status === 200) {
        const updateData = {
          ...requestData,
          status: true,
          id: id,
          api_response: chatDetail?.groupData
            ? {
                body: sentMessageResponse.data.Body,
                ...sentMessageResponse.data,
                groupID: chatDetail?.groupData && chatDetail.groupData.groupID,
              }
            : sentMessageResponse.data,
        };

        const updateResponse = await updateChat(updateData);

        if (chatDetail?.groupData) {
          //Needs to be Checked***
          !groupChatMessages.length && get_group_chat_messages();

          groupChatMessages.splice(groupChatMessages.length - 1, 1, {
            ...updateData,
          });
          setGroupChatMessages([...groupChatMessages]);
        } else {
          userChat.splice(userChat.length - 1, 1, { ...updateData });
          chatArray = userChat;
          setUserChat([...userChat]);
        }

        await sidebarRef.current.addLastMessageInUserList(
          updateData,
          chatDetail?.groupData ? chatDetail.groupData.groupID : contactID
        );
      } else {
        // console.error("API error", sentMessageResponse, contactID);
        if (
          sentMessageResponse.status === 440 ||
          sentMessageResponse.status === 400 ||
          sentMessageResponse.status === 401
        ) {
          if (sentMessageResponse?.error_description) {
            toast.error(sentMessageResponse.error_description);
          }
          if (sentMessageResponse?.message) {
            toast.error(sentMessageResponse.message);
          }
          const deletedResponse = await deleteChatMessage({
            msg_id: id,
            chatDetail,
          });
          if (deletedResponse.status === 1) {
            if (chatDetail?.groupData) {
              groupChatMessages.pop();
              setGroupChatMessages(groupChatMessages);
              await sidebarRef.current.fetchAllChats(
                null,
                chatDetail.groupData.groupID
              );
            } else {
              chatArray.pop();
              setUserChat(chatArray);
              await sidebarRef.current.showModelAndSetUpdateData(chatDetail);
            }
          }
        } else {
          toast.error(
            sentMessageResponse?.message
              ? sentMessageResponse.message
              : "Something went wrong !"
          );
        }
      }
    } else {
      console.log("check error.");
    }
  };

  const setFileDataFunction = (file, setValue, inputType) => {
    if (file) {
      setValue(file);
      setInputType(inputType);
    } else {
      setValue({});
      setInputType("message");
    }
  };

  const startMicRecording = () => {
    setRecordDetail({});
    setRecord(true);
  };

  const stopMicRecording = () => {
    setRecordDetail({});
    setRecord(false);
    setInputType("message");
  };

  const getRecordDetailFormMicRecord = (data) => {
    setRecordDetail(data);
    setInputType("voice");
    setRecord(false);
  };

  const clearConversation = async (
    show_alert = false,
    chat_detail = chatDetail
  ) => {
    try {
      const all_messages = await getAllChats();
      if (all_messages.status == 1) {
        const current_chat = all_messages.data.filter((chat) => {
          if (chat?.api_response?.groupID) {
            return (
              chat?.api_response?.groupID == chat_detail?.groupData?.groupID
            );
          } else {
            return (
              chat?.api_response?.sendingUserID ==
                chat_detail?.userData?.contactID &&
              chat?.api_response?.recipientUserID ==
                chat_detail?.userData?.SenderID
            );
          }
        });
        //Only for groups
        if (chatDetail.groupData) {
          const allGroupChatData = await getAllGroupChats();

          const current_group_chat = allGroupChatData.data.find(
            (el) => el.group_id == chatDetail.groupData.groupID
          );

          const update_group_chat = await updateGroupChat({
            ...current_group_chat,
            chat_ids: [],
          });
        }

        //For both: user and group
        if (current_chat.length) {
          await current_chat.forEach(async (msg) => {
            await deleteChatMessage({ msg_id: msg.id, chatDetail });
          });
          if (show_alert) {
            toast.success("Chat cleared successfully !");
            //remove last message of chat and all messages from chat box
            if (chatDetail.groupData) {
              setGroupChatMessages([]);
              await updateGroup({ ...chatDetail, lastMessage: {} });
            }
          }
        } else {
          show_alert && toast.warn("There's nothing to delete !");
        }
      }
    } catch (error) {
      console.log("clearConversationError", error);
    }
  };

  const get_group_chat_messages = async (selected_chat = chatDetail) => {
    if (selected_chat.is_verified) {
      const all_group_chats = await getAllGroupChats();
      if (all_group_chats.status === 1) {
        const selectedGroup = all_group_chats.data.find(
          (gc) => gc.group_id == selected_chat?.groupData?.groupID
        );
        if (selectedGroup?.group_id == chatDetail?.groupData?.groupID) {
          let all_messages = await getAllChats();
          if (all_messages.status === 1) {
            let this_group_messages = all_messages.data;
            this_group_messages = this_group_messages.filter(
              (message) =>
                selected_chat?.groupData?.groupID ==
                message?.api_response?.groupID
            );

            setGroupChatMessages(this_group_messages);
          }
        } else {
          setGroupChatMessages([]);
          console.warn("no group chat found", selectedGroup);
        }
      } else {
        console.error("all_group_chatsELSE", all_group_chats);
      }
    }
  };

  return (
    <>
      {/* Full page loader */}
      <XYCenteredContent
        className={`position-absolute w-100 ${!loading && "d-none"}`}
        style={{ zIndex: "100", backgroundColor: "#00000025" }}
        justify="center"
        height="100%"
      >
        <CMLoader />
      </XYCenteredContent>
      <div className="d-flex">
        <Sidebar
          sidebarRef={sidebarRef}
          sidebar={sidebar}
          setSidebar={setSidebar}
          getChatDetail={getChatDetail}
          getAdcampaign={getAdcampaign}
          clearConversation={clearConversation}
          contactID={contactID}
          chatDetail={chatDetail}
          setChatDetail={setChatDetail}
          callLogoutAPI={callLogoutAPI}
          setContactID={setContactID}
          callPendingInviteAPI={callPendingInviteAPI}
          setGroupChatMessages={setGroupChatMessages}
          clearMessageInput={clearMessageInput}
        />
        <ChatMain>
          <ChatHeader justify={"space-between"} className="px-4">
            <ChatDetailWrap>
              <SidebarToggle onClick={() => setSidebar(true)}>
                <img
                  src="./images/ic_menu.ico"
                  alt="menu"
                  width="25"
                  height="25"
                />
              </SidebarToggle>
              <XYCenteredContent>
                <ProfileImageContainer>
                  <OnlineStatus isOnline={isOnline} />
                  <ProfileImage
                    src={
                      (chatDetail?.userData &&
                        (chatDetail.userData.profilePic ||
                          chatDetail.userData.Profilepic ||
                          "/images/user.ico")) ||
                      (chatDetail?.groupData &&
                        (chatDetail.groupData.profilePic ||
                          chatDetail.groupData.Profilepic ||
                          "./images/g_icon.ico"))
                    }
                    onError={(e) =>
                      chatDetail.is_ad &&
                      (e.target.src = "./images/ad_icon.jpg")
                    }
                    width="60px"
                    height="60px"
                    alt=""
                  />
                </ProfileImageContainer>
                <ChatDetail>
                  <NameDesc>
                    <span>
                      {chatDetail?.userData?.SenderName ||
                        chatDetail?.groupData?.groupName}
                    </span>
                    {chatDetail?.userData?.senderPhonewithCcode ? (
                      <h6>( +{chatDetail?.userData?.senderPhonewithCcode} )</h6>
                    ) : (
                      <p className="text-secondary ms-1">
                        ({chatDetail?.groupData?.groupDescription})
                      </p>
                    )}
                  </NameDesc>
                  <XYCenteredContent justify={"flex-start"}>
                    {chatDetail?.userData
                      ? isTyping && <UserTyping>typing . . .</UserTyping>
                      : groupTyping.groupID ==
                          chatDetail?.groupData?.groupID && (
                          <UserTyping>{groupTyping.typing_str}</UserTyping>
                        )}
                  </XYCenteredContent>
                </ChatDetail>
              </XYCenteredContent>
            </ChatDetailWrap>
            <UserActions>
              <div className="position-relative">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src="./images/show.ico" width="23" alt="" />
                  </Dropdown.Toggle>

                  <ActionsDropDown>
                    {!chatDetail?.is_ad && (
                      <>
                        <Dropdown.Item className="p-0">
                          <UserActionButton onClick={() => setSelect(true)}>
                            <SelectMultiple />
                            <span>Select Messages</span>
                          </UserActionButton>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-0">
                          <UserActionButton
                            onClick={async () => {
                              await clearConversation(true);
                              setTimeout(() => {
                                sidebarRef.current.fetchAllChats(
                                  chatDetail?.userData?.webID || null,
                                  chatDetail?.groupData?.groupID || null
                                );
                              }, 200);
                            }}
                          >
                            <ClearChat />
                            <span>Clear Chat</span>
                          </UserActionButton>
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item className="p-0">
                      <UserActionButton
                        onClick={(e) => callLogoutAPI(e, "complete")}
                      >
                        <img src="./images/ic-logout.svg" />
                        <span>Logout</span>
                      </UserActionButton>
                    </Dropdown.Item>
                  </ActionsDropDown>
                </Dropdown>
              </div>
            </UserActions>
          </ChatHeader>
          <ChatBoxWrapper is_ad={chatDetail?.is_ad} ref={messagesEndRef}>
            {chatDetail?.is_ad && (
              <h5
                dangerouslySetInnerHTML={{
                  __html: chatDetail?.lastMessage?.api_response?.body,
                }}
              />
            )}
            <ChatBox
              notificationPermissionState={notificationPermissionState}
              chatDetail={chatDetail}
              select={select}
              setSelect={setSelect}
              setReply={setReply}
              chatMessages={
                chatDetail?.groupData ? groupChatMessages : userChat
              }
              setGroupChatMessages={setGroupChatMessages}
              sidebarRef={sidebarRef}
              messageref={messageref}
              searchMode={searchMode}
            />
          </ChatBoxWrapper>

          {!chatDetail?.is_ad && (
            // <MembersTyping>{senderTyping}</MembersTyping>
            <MessageInputWrapper justify="space-between">
              {notificationPermissionState !== "granted" && (
                <PermissionInstructions />
              )}
              <AddFiles>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    disabled={notificationPermissionState !== "granted"}
                  >
                    <img src="./images/plus.ico" width="25px" alt="file" />
                  </Dropdown.Toggle>

                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) =>
                      setFileDataFunction(
                        e.target.files[0],
                        setPhotoOrVideo,
                        "photoOrVideo"
                      )
                    }
                    id="image-video-input"
                    accept=".jpg, .jpeg, .gif, .png, .tif, .tiff, .bmp, .ico, .mp4, .avi, .wmv, .avc"
                    // How to allow input type=file to select the same file in react component
                    // https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) =>
                      setFileDataFunction(
                        e.target.files[0],
                        setFileDocument,
                        "document"
                      )
                    }
                    id="document-input"
                    accept=".txt, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) =>
                      setFileDataFunction(e.target.files[0], setVoice, "voice")
                    }
                    id="voice-input"
                    accept=".mp3, .ogg, .wma, .aac, .wav"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />

                  {show && (
                    <WebcamCapture
                      setShow={setShow}
                      show={show}
                      handleClose={handleClose}
                      setPhotoOrVideo={setPhotoOrVideo}
                      setFileDataFunction={setFileDataFunction}
                    />
                  )}
                  <SelectFiles>
                    <label htmlFor="image-video-input" type="button">
                      <MdOutlinePhotoSizeSelectActual />
                      <span>Photo or video</span>
                    </label>
                    <label htmlFor="document-input" type="button">
                      <IoDocumentTextOutline />
                      <span>Document</span>
                    </label>
                    <label onClick={handleShow}>
                      <BsCamera />
                      <span>Camera</span>
                    </label>
                    <label htmlFor="voice-input" type="button">
                      <AiOutlineAudio />
                      <span>Audio</span>
                    </label>
                  </SelectFiles>
                </Dropdown>
              </AddFiles>
              {chatDetail?.groupData && (
                <HiUsers
                  size={28}
                  color="#214881"
                  cursor={"pointer"}
                  onClick={() => setSendToMembers(!sendToMembers)}
                  title="Send message to selected members only"
                />
              )}
              <form
                className="message-form"
                id="sendMessage"
                style={{ width: "100%" }}
                onSubmit={sendMessageToSenderOrGroup}
              >
                <SelectedData showSubject={showSubject}>
                  <div className="inputs-container">
                    <div className="left-column">
                      <ContentBarMain>
                        <ContentBarWrap>
                          {(inputType === "photoOrVideo" ||
                            inputType === "document" ||
                            inputType === "camera" ||
                            inputType === "voice") && (
                            <ContentToBeSent
                              reply_id={reply?.id}
                              containsMembers={sendToMembers}
                            >
                              <div className="w-100 h-100 position-relative">
                                <CloseContentBar
                                  onClick={() => {
                                    if (photoOrVideo?.name) {
                                      setFileDataFunction(
                                        null,
                                        setPhotoOrVideo,
                                        ""
                                      );
                                    }
                                    if (fileDocument?.name) {
                                      setFileDataFunction(
                                        null,
                                        setFileDocument,
                                        ""
                                      );
                                    }
                                    if (inputType === "voice") {
                                      if (voice?.name) {
                                        setFileDataFunction(null, setVoice, "");
                                      }
                                    }
                                    if (recordDetail?.blobURL) {
                                      stopMicRecording();
                                    }
                                  }}
                                >
                                  <IoIosClose />
                                </CloseContentBar>
                                <div className="mt-2 pt-1">
                                  <h6>
                                    {inputType === "photoOrVideo" &&
                                      photoOrVideo.name}
                                    {inputType === "document" &&
                                      fileDocument.name}
                                    {inputType === "camera" &&
                                      photoOrVideo.name}
                                    {inputType === "voice" && (
                                      <>
                                        {voice?.name && voice.name}
                                        {recordDetail?.blobURL && (
                                          <Wavesurfer
                                            url={recordDetail?.blobURL}
                                            setRecordDuration={
                                              setRecordDuration
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </ContentToBeSent>
                          )}
                          {sendToMembers && (
                            <ContentToBeSent id="memberListParent">
                              <div className="w-100 h-100 position-relative">
                                <CloseContentBar
                                  onClick={() => {
                                    setSendToMembers(false);
                                  }}
                                >
                                  <IoIosClose />
                                </CloseContentBar>
                                <div className="my-2">
                                  {sendToMembers && (
                                    <SelectSpecificMembers
                                      chatDetail={chatDetail}
                                      setSpecificMembers={setSpecificMembers}
                                    />
                                  )}
                                </div>
                              </div>
                            </ContentToBeSent>
                          )}
                          {reply?.id && (
                            <ContentToBeSent containsMembers={sendToMembers}>
                              <XYCenteredContent
                                justify={"space-between"}
                                className="w-100 h-100 position-relative"
                              >
                                <CloseContentBar onClick={() => setReply({})}>
                                  <IoIosClose />
                                </CloseContentBar>
                                <div>
                                  <h6>
                                    {reply.role === "user"
                                      ? "You"
                                      : reply.api_response?.title}
                                  </h6>
                                  <span>
                                    {/* 1 - text - null 2 - voice - mp3, ogg, wma,
                                  aac, wav 3 - video - mp4, avi, wmv, avc 4 -
                                  image - jpg, jpeg, gif, png, tif, tiff, bmp,
                                  ico 5 - document - txt, doc, docx, xls, xlsx,
                                ppt, pptx, pdf */}
                                    {Number(reply?.api_response?.typeID) ===
                                      1 && reply?.api_response?.body}
                                    {Number(reply?.api_response?.typeID) ===
                                      2 &&
                                      reply?.api_response?.filePath.split(
                                        "/"
                                      )[1]}
                                    {Number(reply?.api_response?.typeID) ===
                                      3 &&
                                      reply?.api_response?.filePath.split(
                                        "/"
                                      )[1]}
                                    {Number(reply?.api_response?.typeID) ===
                                      4 &&
                                      reply?.api_response?.filePath.split(
                                        "/"
                                      )[1]}
                                    {Number(reply?.api_response?.typeID) ===
                                      5 &&
                                      reply?.api_response?.filePath.split(
                                        "/"
                                      )[1]}
                                  </span>
                                </div>
                                {reply?.api_response?.typeID === 3 &&
                                  `${
                                    reply?.api_response?.filePath.split("/")[1]
                                  }`}
                                {Number(reply?.api_response?.typeID) === 4 && (
                                  <ReplyImage
                                    src={reply?.api_response?.filePath}
                                  />
                                )}
                              </XYCenteredContent>
                            </ContentToBeSent>
                          )}
                        </ContentBarWrap>
                      </ContentBarMain>
                    </div>
                    <div className="right-column">
                      {showSubject && showTo && (
                        <div className="email-tags-container">
                          To:{" "}
                          {to.map((entry, index) => {
                            const name = entry.includes("|")
                              ? entry.split("|")[0]
                              : entry;
                            return (
                              <span key={index} className="email-tag">
                                {name}{" "}
                                <button
                                  onClick={() =>
                                    removeContact(
                                      index,
                                      setTo,
                                      selectedToContacts,
                                      setToContacts,
                                      setSelectedToContacts
                                    )
                                  }
                                >
                                  ×
                                </button>
                              </span>
                            );
                          })}
                          <input
                            type="email"
                            className="form-input"
                            placeholder="Type name or email"
                            onKeyDown={(e) => handleKeyDown(e, setTo)}
                          />
                        </div>
                      )}
                      <div className="d-flex flex-wrap flex-lg-nowrap w-full justify-content-between">
                        <div className="w-100 me-1">
                          {showSubject && showCC > 0 && (
                            <div className="email-tags-container">
                              CC:{" "}
                              {cc.map((entry, index) => {
                                const name = entry.includes("|")
                                  ? entry.split("|")[0]
                                  : entry;
                                return (
                                  <span key={index} className="email-tag">
                                    {name}{" "}
                                    <button
                                      onClick={() =>
                                        removeContact(
                                          index,
                                          setCc,
                                          selectedContacts,
                                          setContacts,
                                          setSelectedContacts
                                        )
                                      }
                                    >
                                      ×
                                    </button>
                                  </span>
                                );
                              })}
                              <input
                                type="email"
                                className="form-input"
                                placeholder="Type email"
                                onKeyDown={(e) => handleKeyDown(e, setCc)}
                              />
                            </div>
                          )}
                        </div>

                        <div className="w-100">
                          {showSubject && showBCC > 0 && (
                            <div className="email-tags-container">
                              BCC:{" "}
                              {bcc.map((entry, index) => {
                                const name = entry.includes("|")
                                  ? entry.split("|")[0]
                                  : entry;
                                return (
                                  <span key={index} className="email-tag">
                                    {name}{" "}
                                    <button
                                      onClick={() =>
                                        removeContact(
                                          index,
                                          setBcc,
                                          selectedBccContacts,
                                          setBccContacts,
                                          setSelectedBccContacts
                                        )
                                      }
                                    >
                                      ×
                                    </button>
                                  </span>
                                );
                              })}
                              <input
                                type="email"
                                className="form-input"
                                placeholder="Type email"
                                onKeyDown={(e) => handleKeyDown(e, setBcc)}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {showSubject && (
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Subject"
                          onChange={(e) => setSubject(e.target.value)}
                          value={subject}
                        />
                      )}

                      {showSubject ? (
                        <textarea
                          className="message-box"
                          placeholder="Message..."
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                          disabled={notificationPermissionState !== "granted"}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-input w-100 w-md-75"
                          placeholder="Enter Text"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                          disabled={notificationPermissionState !== "granted"}
                        />
                      )}
                    </div>
                  </div>

                  <IconsContainer className="" showSubject={showSubject}>
                    {showSubject && (
                      <CancelButton
                        type="button"
                        variant="primary"
                        onClick={() => {
                          setShowTo((prev) => !prev);
                          setShowCC((prev) => !prev);
                          setShowBCC((prev) => !prev);
                        }}
                      >
                        <MultiUserImage alt="" />
                      </CancelButton>
                    )}

                    <CancelButton
                      type="button"
                      variant={showSubject ? "primary" : "secondary"}
                      onClick={() => {
                        setShowSubject(!showSubject);
                        setSubject("");
                        setShowTo(false);
                        setShowCC(false);
                        setShowBCC(false);
                        setTo([]);
                        setCc([]);
                        setBcc([]);
                      }}
                      disabled={notificationPermissionState !== "granted"}
                    >
                      <CancelImage showSubject={!showSubject} alt="" />
                    </CancelButton>
                  </IconsContainer>
                </SelectedData>
              </form>

              <SmartChatList
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveContacts}
                onSetContacts={setContacts}
                contacts={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setSelectedCC={setCc}
                displayText={"Cc"}
              />

              <SmartChatList
                isOpen={isCCModalOpen}
                onClose={() => setIsCCModalOpen(false)}
                onSave={handleBccSaveContacts}
                onSetContacts={setBccContacts}
                contacts={bccContacts}
                selectedContacts={selectedBccContacts}
                setSelectedContacts={setSelectedBccContacts}
                setSelectedCC={setBcc}
                displayText={"Bcc"}
              />

              <SmartChatList
                isOpen={isToModalOpen}
                onClose={() => setIsToModalOpen(false)}
                onSave={handleToSaveContacts}
                onSetContacts={setToContacts}
                contacts={toContacts}
                selectedContacts={selectedToContacts}
                setSelectedContacts={setSelectedToContacts}
                setSelectedCC={setTo}
                displayText={"To"}
              />

              <ContentBarRight>
                {showSubject && showTo && (
                  <CancelButton
                    type="button"
                    variant="primary"
                    onClick={() => setIsToModalOpen((prev) => !prev)}
                  >
                    <UserImage alt="" />
                  </CancelButton>
                )}

                {showSubject && showTo && (
                  <CancelButton
                    type="button"
                    variant="primary"
                    onClick={() => setIsModalOpen((prev) => !prev)}
                  >
                    <CCImage alt="" />
                  </CancelButton>
                )}

                {showSubject && showTo && (
                  <CancelButton
                    type="button"
                    variant="primary"
                    onClick={() => setIsCCModalOpen((prev) => !prev)}
                  >
                    <BCCImage alt="" />
                  </CancelButton>
                )}

                {userTyping ? (
                  <Button
                    type="submit"
                    form="sendMessage"
                    disabled={notificationPermissionState !== "granted"}
                  >
                    <SendIcon alt="send" />
                  </Button>
                ) : (
                  <Button disabled={notificationPermissionState !== "granted"}>
                    <MicIcon alt="mic" onClick={startMicRecording} />
                  </Button>
                )}
              </ContentBarRight>

              {notificationPermissionState !== "granted" && (
                <PermissionInstructions />
              )}
            </MessageInputWrapper>
          )}

          {/* this is separate input  ="space-between">box for record voice */}
          {record && (
            <>
              <AudioElement
                getRecordDetailFormMicRecord={getRecordDetailFormMicRecord}
                stopMicRecording={stopMicRecording}
              />
            </>
          )}
        </ChatMain>
      </div>
    </>
  );
};

export default Chat;
